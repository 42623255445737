<template>
	<div class="page" style="background: #ebf1fa; word-break: break-all; overflow: hidden; ">
		<div class="inpage" id="page_start">
			<div v-for="(wj,idx) in WjInfo" style="padding: 12px;">
				<div style="font-size:18px ;color: #000000; margin-bottom: 20px;">{{wj.wj_content.title}}</div>
				
				
				<img src="//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586152692725760687.jpeg"  style="width: 100%; vertical-align: top;" />
				

				<div class="btnsubmit" @click="startNow">开始</div>

			</div>
		</div>

		<div class="inpage" id="page1" style="display:none; z-index: 2;" v-if="preTM">
			<div style="padding: 20px;">
				<div>
					<div style="width: 36px; float: left; color: #000000;">
						{{tmidx+1}}、
					</div>
					<div style="margin-left: 36px;color: #000000;">
						{{preTM.title}}
					</div>
				</div>

				<div>
					<div  v-for="(xx,xxidx) in preTM.xx" style="display: inline-block; padding: 20px;">
						<label v-if="preTM.type=='radio'" @click="setRadio(xx,preTM.xx)">
							<input type="radio" :name="preTM.title" :checked="xx.ckd" /> {{xx.title}} <input v-model="xx.txt" class="inputtxt"
							 type="text" v-if="xx.write" />
						</label>
						<label v-if="preTM.type=='checkbox'">
							<input type="checkbox" :name="preTM.title" @change="setCheckbox(xx,preTM.xx)" :checked="xx.ckd" /> {{xx.title}}
							<input v-model="xx.txt" class="inputtxt" type="text" v-if="xx.write" />
						</label>

					</div>
				</div>
			</div>
		</div>
		<div class="inpage" id="page2" style="display:none; z-index: 1;" v-if="nowTM">
			<div style="padding: 20px;">
				<div>
					<div style="width: 36px; float: left; color: #000000;">
						{{tmidx+1}}、
					</div>
					<div style="margin-left: 36px;color: #000000;">
						{{nowTM.title}}
					</div>
				</div>

				<div style="min-height: 65vh;">
					<div :class="xx.show?(xx.correct?'green':'red'):''" v-for="(xx,xxidx) in nowTM.xx" style="display: inline-block; padding: 20px;">
						<label v-if="nowTM.type=='radio'" @click="setRadio(xx,nowTM.xx)">
							<input type="radio" :name="nowTM.title" :checked="xx.ckd" /> {{xx.title}} <input v-model="xx.txt" class="inputtxt"
							 type="text" v-if="xx.write" />
						</label>
						<label v-if="nowTM.type=='checkbox'">
							<input type="checkbox" :name="nowTM.title" @change="setCheckbox(xx,nowTM.xx)" :checked="xx.ckd" /> {{xx.title}}
							<input v-model="xx.txt" class="inputtxt" type="text" v-if="xx.write" />
						</label>

					</div>
				</div>

				<div style="width: 90%; margin: 0 auto;">
					<div style="text-align: center; font-size: 12px; padding: 10px;">
						第{{tmidx+1}}题，共{{total}}题
					</div>
					<div >
						<div style="float: left; width: 44%;" v-if="preTM">
							<div class="btnprev" @click="goPrev">上一题</div>
						</div>
						<div style="margin-left: 55%; width: 45%;">
							<div class="btnnext" @click="goNext" v-if="nextTM">下一题</div>
							<div class="btnnext" v-if="!nextTM" @click="showWriteAddress">提交问卷</div>
						</div>
					</div>




				</div>
			</div>
		</div>

		<div class="inpage" id="page3" style="display:none; z-index: 2;" v-if="nextTM">
			<div style="padding: 20px;">
				<div>
					<div style="width: 36px; float: left; color: #000000;">
						{{tmidx+1}}、
					</div>
					<div style="margin-left: 36px;color: #000000;">
						{{nextTM.title}}
					</div>
				</div>

				<div>
					<div v-for="(xx,xxidx) in nextTM.xx" style="display: inline-block; padding: 20px;">
						<label v-if="nextTM.type=='radio'" @click="setRadio(xx,nextTM.xx)">
							<input type="radio" :name="nextTM.title" :checked="xx.ckd" /> {{xx.title}} <input v-model="xx.txt" class="inputtxt"
							 type="text" v-if="xx.write" />
						</label>
						<label v-if="nextTM.type=='checkbox'">
							<input type="checkbox" :name="nextTM.title" @change="setCheckbox(xx,nextTM.xx)" :checked="xx.ckd" /> {{xx.title}}
							<input v-model="xx.txt" class="inputtxt" type="text" v-if="xx.write" />
						</label>

					</div>
				</div>
			</div>
		</div>
		
		<div id="page_address" class="inpage" style="z-index: 3; display: none;">
			
			<div v-for="(wj,idx) in WjInfo" style="padding: 12px;">
				<div style="height: 30%;"></div>
				<div style="width: 90%; margin: 0 auto; background: #FFFFFF; border-radius: 6px;">
					<div style="padding-top: 10px; color: #000000;">
						感谢您的参与，请输入您的地址接收礼品：
					</div>
					<div style="padding-top: 30px;">
					
						收件人：<input class="inputtxt" v-model="wj.wj_content.realname" type="text" style="width: 60%;" />
					
					</div>
					<div style="padding-top: 30px;">
						手机号：
						<input class="inputtxt" v-model="wj.wj_content.phone" type="text" style="width: 60%;" />
					
					</div>
					<div style="padding-top: 30px;">
						详细地址
						<input class="inputtxt" v-model="wj.wj_content.address" type="text" style="width: 90%;" />
					
					</div>
					
					<div class="btnsubmit" @click="submitData">提交</div>
				</div>
			
			</div>
			
			
			
		</div>

		<div v-if="txok" class="inpage" style="position: fixed; z-index: 99; background: rgba(0,0,0,0.6);">
			<div style="height: 30%;"></div>
			<div style="width: 80%; margin: 0 auto; background: #FFFFFF; border-radius: 6px;">
				<div style="padding: 10px; text-align: center;  padding-top: 40px;">
					申领已成功，请及时反馈信息，还有好礼哦！
				</div>
				<div class="btnsubmit" @click="goShop">

					确定
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {

		data() {
			return {
				WjInfo: [],
				txok: false,
				wj_id: 0,
				uid: 0,
				tmidx: 0,
				preTM: null,
				nowTM: null,
				nextTM: null,
				total: 0
			}
		},
		mounted() {
			document.title = "";
			window.PageOK();
			let pid = this.$route.params.id;
			if (pid.indexOf("_") > 0) {
				this.wj_id = pid.split("_")[0];
				this.uid = pid.split("_")[1];//从哪个用户推荐进来的
			} else {
				this.wj_id = pid;
			}
			// if (this.uid > 0) {
				
			// } else {
			// 	this.getMyself()
			// }
			this.GetList()

			//this.saveNewWJ()


		},
		methods: {

			saveNewWJ() {
				

				wj = JSON.stringify(wj)
				this.$store.dispatch("POST", {
					url: "/api/wj/?action=savenewwj",
					query: {
						title: "医护人员营养健康关爱活动问卷（二）",
						content: encodeURIComponent(wj)
					}
				}).then(result => {})
			},
			startNow() {

				let wj = this.WjInfo[0].wj_content;

				this.nowTM = wj.tm[this.tmidx];
				if (this.tmidx < wj.tm.length - 2) {
					this.nextTM = wj.tm[this.tmidx + 1];
					this.$nextTick(function() {
						$("#page2").animate({
							translate3d: "100%,0,0"
						}, 30, "linear", function() {
							$("#page2").show().animate({
								translate3d: "0%,0,0"
							}, 300, "ease-out", function() {

							})
						})
					})

				} else {
					this.nextTM = null;
				}





			},
			goPrev() {
				let that = this;
				let wj = this.WjInfo[0].wj_content;
				if (this.tmidx >0) {
					this.preTM = wj.tm[this.tmidx - 1];
					this.$nextTick(function() {
						let d=new Date().getTime()
						$("#page1").animate({
							translate3d: "-100%,0,0"
						}, 30, "linear", function() {
							console.log(d-new Date().getTime())
							$("#page1").show().animate({
								translate3d: "0%,0,0"
							}, 300, "ease-out", function() {
								$("#page1").hide()
							})
						})
						$("#page2").animate({
							translate3d: "0%,0,0"
						}, 300, "linear", function() {
							that.nowTM = wj.tm[that.tmidx - 1];
							that.nextTM = wj.tm[that.tmidx];
							that.tmidx--;
							
							if (that.tmidx == 0) {

								that.preTM = null;
							}
						})
					})
				}
			},
			goNext() {
				if(!this.checkCorrect()){
					return
				}
				
				
				let that = this;
				let wj = this.WjInfo[0].wj_content;

				if (this.tmidx < wj.tm.length - 1) {
					this.nextTM = wj.tm[this.tmidx + 1];
					this.$nextTick(function() {
						$("#page3").animate({
							translate3d: "100%,0,0"
						}, 30, "linear", function() {
							$("#page3").show().animate({
								translate3d: "0%,0,0"
							}, 300, "ease-out", function() {
								$("#page3").hide()
							})
						})
						$("#page2").animate({
							translate3d: "0%,0,0"
						}, 300, "linear", function() {
							that.nowTM = wj.tm[that.tmidx + 1];
							that.preTM = wj.tm[that.tmidx];
							that.tmidx++;
							console.log(that.tmidx, that.total)
							if (that.tmidx == that.total - 1) {

								that.nextTM = null;
							}
						})
					})
				}

			},
			checkCorrect(){
				//先检查是否做题
				let ckd = false;
				for(let xx of this.nowTM.xx){
					if(xx.ckd){
						ckd = true;
						break
					}
				}
				if(!ckd){
					$alert("请选择选项")
					return false
				}
				
				if(this.wj_id!=5){
					//检查是否正确选项
					let can_next=true;
					for(let xx of this.nowTM.xx){
						if(xx.correct){
							if(!xx.ckd){
								can_next=false;
								
							}
						}
						
					}
					if(!can_next){
						$Toast("请选择正确答案")
						
						for(let xx of this.nowTM.xx){
							xx.show=true
						}
						
						return false
					}
				}
				
				
				
				
				
				return true
				
			},
			getMyself() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=me"
				}).then(result => {
					this.uid = result.data.data.rows[0].id;
					this.GetList();
				})
			},

			GetList() {
				this.$store.dispatch("GET", {
					url: "/api/wj/?action=getwj&wj_id=" + this.wj_id + "&uid=" + this.uid
				}).then(result => {
					let data = result.data.rows;
					for (let item of data) {
						item.wj_content = JSON.parse((item.wj_content))
						item.anslist = JSON.parse(item.anslist)
						if (item.anslist.total > 0) {
							
							//this.txok=true;
							//item.wj_content = JSON.parse( ( item.anslist.rows[0].wj_ans))
							

						}
						this.total = item.wj_content.tm.length
						for(let tm of item.wj_content.tm){
							for(let xx of tm.xx){
								xx.show=false
							}
						}
					}
					

					this.WjInfo = data
					
					setTimeout(() => {
						//分享
						this.$store.dispatch("SHARE", {
						  query: {
						    shareurl: window.location.href.split('#')[0]
						  },
						  opt: {
						    title: data[0].wj_content.title,
						    desc: data[0].wj_content.desc,
						    link: window.location.href.split("#")[0] + "#/wj/" + this.wj_id,
						    imgUrl: "http://weskerfile.oss-cn-shanghai.aliyuncs.com/gmbaby/8586854371002546904.png",
						    success: function() {}
						  }
						}).then(result => {})
					}, 1000)
					
					

				})
			},
			goShop() {
				wx.closeWindow();
				//this.$router.push("/index")
			},
			showWriteAddress(){
				if(!this.checkCorrect()){
					return
				}
				$("#page_address").show();
			},
			submitData() {
				this.txok = true;
				for (let tm of this.WjInfo[0].wj_content.tm) {
					let ckd = false;
					for (let xx of tm.xx) {
						if (xx.ckd) {
							ckd = true;
							break;
						}
					}
					if (!ckd) {
						$alert("请填写完整");
						return
					}
				}
				this.$store.dispatch("POST", {
					url: "/api/wj/?action=savewj",
					query: {
						wj_id: this.wj_id,
						fmuid:this.uid,
						wj_ans: encodeURIComponent(JSON.stringify(this.WjInfo[0].wj_content)),
						remark: ""
					}
				}).then(result => {
					if (result.data > 0) {
						this.txok = true
					}
				})
			},
			setRadio(xx, xxlist) {
				for (let item of xxlist) {
					item.ckd = false;
				}
				xx.ckd = true;
			},
			setCheckbox(xx, xxlist) {
				xx.ckd = !xx.ckd

			},

		}
	}
</script>
<style @scope>
	.inputtxt {
		height: 30px;
		background: rgba(0, 0, 0, 0);
		border: 0;
		border-bottom: 1px solid #ccc;
		border-radius: 0;
	}

	.btnsubmit {
		width: 100%;
		height: 40px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		border-radius: 4px;
		margin: 50px 0;
	}

	.btnsubmit:active {
		background: #007AFF;
	}

	.inpage {
		background-color: #FFFFFF;
	}

	.btnnext {
		width: 100%;
		height: 40px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		border-radius: 4px;
	}

	.btnnext:active {
		background: #007AFF;
	}

	.btnprev {
		width: 100%;
		height: 40px;
		background: #f6f6f6;
		color: #8C8C8C;
		text-align: center;
		line-height: 40px;
		border-radius: 4px;
	}

	.btnprev:active {
		background: #007AFF;
	}
	.green{color: green;}
	.red{
		color: red;
	}
</style>
